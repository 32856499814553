import './Booking.css';
import { images } from './images';
import { Desktop, Mobile, MobileFooter } from '../../common/Common';
import Panel, { isShiftLeft } from 'common/panel/Panel';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Watermark from 'common/Watermark';
import { Link, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';

const Booking = () => {
  return (
    <>
      <Desktop><BookingDesktop/></Desktop>
      <Mobile><BookingMobile/></Mobile> 
    </>
  );
}

const BookingContent = ({ setModalImage }) => {
  return (
    <>
      <p> (as of 2025) I am doing guest work at various marvelous studious around the Bay Area! when books are open, the form is shown below. </p>

      <p> some general info: </p>
      <ul>
        <li>location: TBD!</li>
        <li>booking days: Fri, Sat, Sun between 11am-4pm</li>
        <li>rates: $180/hr. I will provide an accurate price during our email conversation!</li>
        <li>deposit: a non-refundable deposit of $50 (flash) or $100-150 (custom) to secure your date (& begin custom design). the deposit will go towards the cost of your tattoo.</li>
        <li>available flash: please look over my <Link className='custom-link' to={'https://www.instagram.com/wistaria.ink/'}>"available" story highlights</Link> on instagram.</li>
      </ul>
      <iframe title="booking form" src="https://docs.google.com/forms/d/e/1FAIpQLSdvBEmT175tJIxCe7k-Qy7lQHE2VekRjdztKWSiT6tAXbuPDw/viewform?embedded=true" width="100%" height="2500" frameBorder="0">Loading…</iframe>
    </>
  )
}

const BookingDesktop = () => {
  const location = useLocation();
  const [modalImage, setModalImage] = useState(null);

  useEffect(() => {
    const closeModal = (e) => {
      if (e.key === 'Escape') {
        setModalImage(null);
      }
    }
    window.addEventListener('keydown', closeModal);
    return () => window.removeEventListener('keydown', closeModal)
  }, []);

  useEffect(() => {
    document.body.classList.toggle('modal-image-shown', Boolean(modalImage));
  }, [modalImage]);
  
  return (
    <>
      <Watermark />
      <Panel />
      <div 
        id='booking-desktop' 
        className={`desktop-main desktop-main__fade-in${isShiftLeft(location) ? '-shift' : ''}`}
      >        
        <div className='desktop-main__content'>
          <BookingContent setModalImage={setModalImage} />
        </div>
      </div>
      { modalImage &&
        <>
          <div className='image-modal'>
            <div className='image-modal__background' onClick={() => setModalImage(null)} />
            <div className='image-modal__exit' onClick={() => setModalImage(null)}>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </div>
            <img src={modalImage.path} alt={modalImage.alt} />
          </div>
        </>
      }
    </>
  )
}

const BookingMobile = () => {
  const [modalImage, setModalImage] = useState(null);
  
  useEffect(() => {
    document.body.classList.toggle('modal-image-shown', Boolean(modalImage));
  }, [modalImage]);

  return (
    <div id='booking-mobile'>
      <Watermark />
      <Panel />
      <div className='mobile-main'>
        <div className='mobile-main__content'>
          <BookingContent setModalImage={setModalImage} />
        </div>
      </div>
      { modalImage &&
        <>
          <div className='image-modal image-modal-mobile'>
            <div className='image-modal__background' onClick={() => setModalImage(null)} />
            <div className='image-modal__exit' onClick={() => setModalImage(null)}>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </div>
            <img src={modalImage.path} alt={modalImage.alt} />
          </div>
        </>
      }
      <MobileFooter />
    </div>
  )
}

export default Booking;
